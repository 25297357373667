@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Delius', sans-serif;
}

body {
  margin: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(./assets/img/bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

  height: 100vh;
}

